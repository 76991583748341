import React, { useState, useRef } from 'react'
import { ArrowButton } from '@truphone/buttons'
import MapComponent from './MapComponent'

export default function Offices(props) {
  const [currentLocation, setCurrentLocation] = useState(
    props.offices && props.offices.length > 0 ? props.offices[0] : props.position
  )

  const officeRefs = useRef([])
  const block = useRef(null)

  const handleLocation = (location, i) => {
    setCurrentLocation(location)

    if (window.innerWidth > 1024) {
      block.current.scrollTo({
        top: officeRefs.current[i].offsetTop,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  return (
    <div className="bg-gray-100 pt-24 mt-2">
      <div className="relative ">
        {props.offices && (
          <div className="container mx-auto lg:px-6">
            <div className="w-full lg:w-1/2 py-4 h-screen overflow-y-auto" ref={block}>
              {props.offices.map((office, i) => {
                const selected = office === currentLocation
                return (
                  <div
                    key={`office-${i}`}
                    className="relative mb-3 cursor-pointer "
                    ref={(el) => (officeRefs.current[i] = el)}
                    onClick={() => handleLocation(office, i)}
                  >
                    <div
                      className={`${
                        selected ? 'bg-blue-900 text-white' : 'bg-white hover:shadow'
                      } p-6 lg:my-2 lg:mr-4 lg:rounded`}
                    >
                      {office.topTitle && (
                        <p className="tracking-widest text-sm font-medium uppercase">
                          {office.topTitle}
                        </p>
                      )}
                      {office.title && <h3 className="mt-3 mb-2 text-2xl">{office.title}</h3>}
                      {office.address && (
                        <div
                          className="text-sm whitespace-pre-line"
                          dangerouslySetInnerHTML={{ __html: office.address }}
                        />
                      )}
                      {office.phone && (
                        <a
                          className={`${
                            selected ? 'text-white underline' : 'text-blue-100'
                          } text-sm inline-block mt-2`}
                          href={`tel:${office.phone}`}
                        >
                          {office.phone}
                        </a>
                      )}
                      <br />
                      {office.website && (
                        <a
                          className={`${
                            selected ? 'text-white underline' : 'text-blue-100'
                          } text-sm mt-2 inline-block`}
                          href={office.website}
                        >
                          {office.website}
                        </a>
                      )}
                      {props.linkText && !selected && (
                        <ArrowButton
                          className={`text-blue-100 text-base mt-4 lg:mt-2 font-semibold justify-end`}
                        >
                          {props.linkText}
                        </ArrowButton>
                      )}
                    </div>
                    {selected && (
                      <div className="lg:hidden relative h-60 w-full">
                        <MapComponent
                          position={currentLocation.location}
                          zoom={props.zoom}
                          location={currentLocation}
                        />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <div className="hidden lg:block absolute h-full w-full lg:w-1/2 right-0 top-0">
          <MapComponent
            position={currentLocation.location}
            zoom={props.zoom}
            location={currentLocation}
          />
        </div>
      </div>
    </div>
  )
}
