import React, { useRef, useState, useEffect } from 'react'
import { Wrapper, Status } from '@googlemaps/react-wrapper'

export default function MapComponent(props) {
  function MyMapComponent({ center, zoom, children }) {
    const ref = useRef(null)
    const [map, setMap] = useState()
    useEffect(() => {
      if (ref.current && !map) {
        setMap(
          new window.google.maps.Map(ref.current, {
            center,
            zoom,
            streetViewControl: false,
            mapTypeControl: false,
            minZoom: 2,
            maxZoom: 18
          })
        )
      }
    }, [ref, map])

    return (
      <>
        <div className="w-full h-full" ref={ref} id="map" />
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            // set the map prop on the child component
            return React.cloneElement(child, { map })
          }
        })}
      </>
    )
  }

  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <h2>Loading</h2>
      case Status.FAILURE:
        return <h2>Loading</h2>
      case Status.SUCCESS:
        return <h2>Success</h2>
    }
  }

  const Marker = (options) => {
    const [marker, setMarker] = useState()

    useEffect(() => {
      if (!marker) {
        setMarker(new window.google.maps.Marker())
      }

      // remove marker from map on unmount
      return () => {
        if (marker) {
          marker.setMap(null)
        }
      }
    }, [marker])
    useEffect(() => {
      if (marker) {
        marker.setOptions(options)

        if (options.location) {
          let infoWindow = new window.google.maps.InfoWindow({
            content: `<b>${options.location.title}</b> <br />
          ${options.location.address}`,
            position: options.position
          })

          marker.addListener('click', () => {
            infoWindow.open(options.map)
          })
        }
      }
    }, [marker, options])
    return null
  }

  const center = { lat: props.position.lat, lng: props.position.lng }
  const position = { lat: props.position.lat, lng: props.position.lng }
  const zoom = props.zoom || 16
  return (
    <Wrapper apiKey={'AIzaSyC924EAhkVtTwRuuM54GAlj8wJK7kHACPU'} render={render}>
      <MyMapComponent center={center} zoom={zoom}>
        <Marker position={position} location={props.location} />
      </MyMapComponent>
    </Wrapper>
  )
}
